<template>
    <div class="box" v-loading="loading">
        <h2>
            地址管理
            <span class="hints"
                >您已创建<i>{{ total }}</i
                >个地址</span
            >
        </h2>

        <div class="address">
            <div class="item" style="margin-right: 10px;" v-for="(addr, index) in addressList" :key="index">
                <div class="item-top">
                    <span class="name">{{ addr.name }}</span>
                    <span class="set-default" :class="{ on: addr.is_default == 1 }" @click="setDefault(addr.id)">设为默认收货地址</span>
                    <span class="edit control" @click="$router.push({ path: '/member/address_edit', query: { id: addr.id } })">编辑</span>
                    <span class="delete control" @click="del(addr.id)">删除</span>
                </div>
                <div class="item-con">
                    <div class="item-row">详细地址：{{ addr.address }}</div>
                    <div class="item-row">所在地址：{{ addr.full_address }}</div>
                    <div class="item-row">联系手机：{{ addr.mobile }}</div>
                    <div class="item-row" oninput="if(value.length>6)value=value.slice(0,6)">邮政编码：{{ addr.telephone }}</div>
                </div>
            </div>
            <div class="item add" @click="$router.push('/member/address_edit')">
                <img src="~img/alibaba/add.png" />
                添加地址
            </div>
        </div>
    </div>
</template>

<script>
import { addressList, setDefault, deleteAddress } from "@/api/member/member"

export default {
    name: "delivery_address",
    components: {},
    data: () => {
        return {
            addressList: [],
            total: 0,
            currentPage: 1,
            pageSize: 8,
            loading: true,
            yes: true
        }
    },
    created() {
        this.getListData()
    },
    mounted() {
        let self = this
        setTimeout(function () {
            self.yes = false
        }, 300)
    },
    methods: {
        getListData() {
            addressList({
                page: this.currentPage,
                page_size: this.pageSize
            })
                .then((res) => {
                    const { count, page_count, list } = res.data
                    this.total = count
                    this.addressList = list
                    this.loading = false
                    console.log(this.addressList)
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err.message)
                })
        },
        handlePageSizeChange(size) {
            this.pageSize = size
            this.refresh()
        },
        handleCurrentPageChange(page) {
            this.currentPage = page
            this.refresh()
        },
        refresh() {
            this.loading = true
            this.getListData()
        },
        /**
         * 设为默认
         */
        setDefault(id) {
            setDefault({
                id: id
            })
                .then((res) => {
                    this.refresh()
                    this.$message({
                        message: "修改默认地址成功",
                        type: "success"
                    })
                })
                .catch((err) => {
                    this.$message.error(err.message)
                })
        },

        /**
         * 添加/编辑地址
         */
        addAddress(type, id) {
            if (type == "edit") {
                this.$router.push({ path: "/member/address_edit", query: { id: id } })
            } else {
                this.$router.push({ path: "/member/address_edit" })
            }
        },

        /**
         * 删除地址
         */
        // delAddress(id, is_default) {
        //     this.$confirm("确定要删除该地址吗?", "提示", {
        //         confirmButtonText: "确定",
        //         cancelButtonText: "取消",
        //         type: "warning"
        //     }).then(() => {
        //         if (is_default == 1) {
        //             this.$message({
        //                 type: "warning",
        //                 message: "默认地址，不能删除!"
        //             })
        //             return
        //         }

        //         deleteAddress({
        //             id: id
        //         })
        //             .then((res) => {
        //                 this.refresh()
        //                 this.$message({ message: res.message, type: "success" })
        //             })
        //             .catch((err) => {
        //                 this.$message.error(err.message)
        //             })
        //     })
        // },

        // 删除地址
        del(e) {
            console.log(e)

            deleteAddress({
                id: e
            })
                .then((res) => {
                    if (res.code == 0) {
                        // console.log(res)
                    }
                    this.getListData()
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }
}
</script>
<style lang="scss" scoped>
.box {
    width: 100%;
    position: relative;

    h2 {
        font-size: 24px;
        color: $ns-text-color-black;
        padding-bottom: 5px;
        border-bottom: 1px solid #d9d9d9;

        .hints {
            margin-left: 20px;
            font-size: 12px;
            color: $ns-text-color-gray;
            font-weight: 400;
            vertical-align: -1px;
            font-size: $ns-font-size-lg;

            i {
                color: red;
                margin: 0 5px;
            }
        }
    }

    .address {
        margin-top: 30px;
    }

    .item {
        float: left;
        width: 300px;
        height: 160px;
        // margin-right: 25px;
		margin-right: 10px;
		    margin-bottom: 20px;
        padding: 15px 20px;
        box-sizing: border-box;
        background-image: url(~img/address.png);
        background-size: 100% 100%;

        &.add {
            background-image: none;
            border: 1px solid #d9d9d9;
            display: flex;
            flex-flow: column;
            align-items: center;
            justify-content: center;
            font-size: $ns-font-size-lg;
            color: $ns-text-color-gray;
            cursor: pointer;

            img {
                width: 36px;
                height: 36px;
                display: block;
            }
        }

        &:nth-child(3n) {
            margin: 0;
        }

        .item-top {
            height: 25px;
            display: flex;
            align-items: center;
            margin-bottom: 15px;

            span {
                display: block;
                line-height: 25px;

                &.name {
                    color: $ns-text-color-black;
                    font-size: $ns-font-size-lg;
                    font-weight: bold;
                    width: 64px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }

                &.set-default {
                    font-size: 12px;
                    line-height: 25px;
                    color: $ns-text-color-gray;
                    padding: 0 5px;
                    border-radius: 5px;
                    cursor: pointer;
                    transition: all 0.3s ease;

                    &.on,
                    &:hover {
                        background-color: $base-color;
                        color: #ffffff;
                    }
                }

                &.control {
                    font-size: $ns-font-size-base;
                    color: $ns-text-color-gray;
                    margin-left: 10px;
                    cursor: pointer;

                    &.edit {
                        color: $base-color;
                    }
                }
            }
        }

        .item-con {
            .item-row {
                line-height: 22px;
                color: $ns-text-color-gray;
                font-size: 12px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
    }
}
</style>
